<template>
  <div class="relative h-full w-full">    
    <img 
      class="absolute inset-0 w-full h-full object-cover" 
      src="/public/feast_frontpage.webp" 
      alt="Feast cover photo" 
    />
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <AppHeader class="absolute top-0 inset-x-0"/>
    <div class="absolute bottom-0 left-0 p-12 flex space-x-8">
      <button @click="loginPage" class="bg-white font-bold text-3xl rounded-lg shadow-lg px-8 py-4">Logi sisse</button>
      <button @click="registerPage" class="bg-white font-bold text-3xl rounded-lg shadow-lg px-8 py-4">Registreeri kasutajaks</button>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'frontpage',
});

const loginPage = () => {
  navigateTo("/login")
}

const registerPage=()=>{
  navigateTo("/register")
}

</script>